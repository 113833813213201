.tag {
  margin-bottom: 15px;
}

.imagePreview {
  display: none;
}

.card{
  :global{
    .ant-card-body{
      padding: 15px;
    }
  }
}
