.absolute-center {
    z-index: 1;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
}

.view-finder {
    width: 100px;
    height: 100px;
    border: 2px dashed #c62cf1;
}

.qr-reader {
    width: 100%;
    max-width: 300px;
}

.scan-button {
    color: #ffffff !important;
    background-image: linear-gradient(91deg, #7c27fd, #c62cf1);
    border: none !important;
    border-radius: 50% !important;
    height: 5rem !important;
    width: 5rem !important;
    font-family: "Lato-Bold" !important;
    font-size: 14px !important;
  }

.add-button {
    color: #c62cf1 !important;
    background-color: #ffffff !important;
    border: 1px solid lightslategrey !important;
    border-radius: 10px !important;
    font-family: "Lato-Bold" !important;
    font-size: 14px !important;
}

.page-top-margin {
    margin-top: 8rem;
}

.header-bar{
    height: 80px;
    width: 100%;
    background-color: white;
    z-index: 10; 
    position: fixed;
}

.header {
    position: fixed;
    margin-top:30px;
    margin-left: 80px;
}

.line {
    width: 100%;
    border-bottom: 1px solid lightslategrey !important;
    margin-top: 5px;
    margin-bottom: 5px;
}

.content-md {
    font-size: 16px;
}

h1.scan-heading {
    color: #212529;
    font-size: 20px;
    text-transform: uppercase;
    font-family: var(--font-family-lato-semi-bold);
}

.scan-body {
    height: 800px;
}

.scan-container {
    display: flex;
    width: 60vw;
    flex-direction: column;
    overflow-wrap: break-word;
    flex-grow: 1;
    position: relative;
    justify-content: space-between;
}

#qrCodeScanner{
    border:none !important;
}

#qrCodeScanner video, #qr-shaded-region{
    width: 500px !important;
}

#html5-qrcode-button-camera-stop{
    display: none !important;
}

#qrCodeScanner img[alt="Info icon"]{
    display: none;
}

.scan-result {
    height: 75vh;
    overflow-y: auto;
    padding-right: 20px;
}

.back-button-container {
    width: inherit;
    display: flex !important;
    justify-content: center;
    align-items: center;
    background-color: #ffffff;
    height:10%;
}

.back-button {
    width: 10rem;
}

.tier-logo {
    width: 75px;
    height: 75px;
    border-radius: 50%;
    overflow: hidden;
    object-fit: contain;
}

.checkmark {
    width:25px;
    height:25px;
    position: absolute;
    bottom: -6px;
    left: 50px;
    display: inline-block;
}

.merchant-logo {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    overflow: hidden;
    object-fit: contain;
}

.benefit-card {
    position: relative;
    padding: 0.5rem;
    margin: 10px;
    max-height: 24rem;
    border-radius: 16px;
    box-shadow: 0.5px 1px 2px 2px rgba(0, 0, 0, 0.25);
    color: #343A40;
}

.grey-out-overlay {
    background-color: rgba(128, 128, 128, 0.3);
    position: absolute;
    width: 100%;
    height:100%;
    padding: 0.5rem;
    margin: -10px;
    max-height: 24rem;
    border-radius: 16px;
}

.faro-pink {
    color: #c62cf1 !important;
}

.font-sm {
    font-size: 12px !important;
}

.font-md {
    font-size: 16px !important;
}

.font-lg {
    font-size: 18px !important;
}

.redeem-button {
    height: 30px;
}

.result-card {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    max-height: 24rem;
    border-radius: 16px;
    box-shadow: 1px 2px 4px 2px rgba(0, 0, 0, 0.25);
    color: #343A40;
    padding: 1rem;
}

.result-card-header {
    display: flex;
    width: "100%";
    justify-content: center;
    align-items: center;
    flex-direction: column;
    font-size: 24px;
    font-weight: bold;
    padding-bottom: 1rem;
}

.result-card-section {
    padding-left: 1rem;
    padding-right: 1rem;
    padding-top: .4rem;
    padding-bottom: .4rem;
    border-top: 1px solid rgba(128, 128, 128, 0.5);
}

.invalid-result {
    border-width: 7px;
    border-color: #EB4335;
}

.invalid-message {
    overflow-wrap: break-word;
    width: 100%;
}

.result-icon-container {
    position: relative; 
    height: 70%;
    width: 2rem;
}

.result-icon {
    position: absolute;
     top: 0; 
     left: 0;
}


