.upload {
  img {
    margin: -42px 0;
    object-fit: cover;
    border-radius: 7px;
  }

  .svg-preview {
    svg {
      height: 70px;
      width: 70px;
      fill: #5a5858;

      path{
      fill: #5a5858;
      }
    }
  }
}

.disable-pointer-events {
  pointer-events: none;

  .change-file {
    pointer-events: auto !important;
  }
}

.change-file {
  position: absolute;
  top: -35px;
  right: 0px;
  color: var(--color-brand-button-color) !important;
  font-size: 14px !important;

  div {
    margin-right: 5px;
  }
}