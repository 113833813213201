.tier-list {
  display: flex;
  margin-top: 1.5rem;
  width: calc(100vw - 200px);
  overflow: scroll;
  margin-top: 2rem;
  margin-bottom: 2rem;
  padding: 1rem;
}
.assign-benefits-button {
  height: 30px;
  width: 90%;
  background: #fafafa;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid#dcd8d8;
  padding: 25px 35px 25px 35px;
  margin-top: 1.5rem;
  margin-bottom: 1rem;
  border-radius: 10px;
  cursor: pointer;
}

.disabled-button {
  cursor: not-allowed;
  opacity: 0.6;
  pointer-events: none; 
}

.tierSingleCard {
  width: 90%;
  height: 6rem;
  box-shadow: 0px 15px 25px rgba(9, 9, 9, 0.09);
  font-family: Arial, Helvetica, sans-serif;
  cursor: pointer;
}
.tierCardActive {
  border: 2px solid #902ffd;
  border-color: #902ffd;
}

.benefits-tile {
  width: 90%;
  background: #fafafa;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid#dcd8d8;
  padding: 10px 15px;
  margin-top: 1.5rem;
  margin-bottom: 1rem;
  border-radius: 10px;
}

.benefits-tag {
  // margin-left: 1rem;
  border-radius: 10px;
  font-size: 13px;
  margin-top: 5px;
}
.benefits-icon {
  width: 14px !important;
  margin-right: 7px;
  height: 10px !important;
}
.add-benefits-text {
  color: #902ffd;
}
.tierCardIcon {
  height: 1.7rem;
  width: 1.7rem;
  border-radius: 50%;
  padding: 5px;
  background-color: #57cc99;
  color: #ffffff;
  display: block;
  margin-top: 0.1rem;
  margin-bottom: 0.2rem;
  font-size: 1.1rem;
}
.hide {
  visibility: hidden;
  // display: none;
}
.benefits-tile:hover .hide,
.hide:hover {
  visibility: visible;
  font-size: 17px;
  color: rgb(225, 76, 76);
}
