.tag {
  margin-bottom: 15px;
}

.imagePreview {
  display: none;
}

.sweepstake-card {
  :global {
    .ant-card-cover {
      img {
        object-fit: contain;
      }
    }

    .ant-card-body {
      position: relative;
      padding-top: 40px;

      strong {
        margin-right: 5px;
        color: black;
      }

      :local(.redeemable-period) {
        margin-top: 10px;
        padding: 2px 0px;
        border-bottom: 1px solid var(--antd-default-color);
        line-height: 18px
      }

      :local(.result-date) {
        border-bottom: 1px solid var(--antd-default-color);
        line-height: 18px;
        padding: 2px 0px;
        display: flex;
        flex-direction: column;
      }

      :local(.active-date) {
        font-size: 14px;
        background-color: rgb(248, 246, 93);
        position: absolute;
        padding: 3px 24px;
        width: 100%;
        top: 0;
        left: 0;
      }
    }
  }
}