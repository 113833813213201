.downloadButton {
  padding: 0;
  margin-bottom: 20px;
  text-decoration: underline;
  color: rgba(0, 0, 0, 0.6);

  &:hover {
    color: rgba(0, 0, 0, 0.8);
  }
}

.bulk-delete-container {
  padding-top: 20px;
  width: 100%;

  :global {
    .ant-upload-drag {
      margin: 20px 0;
      border: 1px dashed #d9d9d9;
      border-radius: 8px;
      cursor: pointer;
      transition: border-color 0.3s;
      background: #fafafa;

      &:not(.ant-upload-disabled):hover {
        // border-color: #1890ff;
      }

      &.ant-upload-disabled {
        cursor: not-allowed;

        .ant-upload-drag-icon .anticon {
          color: rgba(0, 0, 0, 0.25);
        }

        .ant-upload-text {
          color: rgba(0, 0, 0, 0.25);
        }
      }

      .ant-upload-drag-container {
        .anticon {
          color: rgba(0, 0, 0, 0.25);
          // color: #1890ff;
          font-size: 14px;
          // transition: color 0.3s;
        }
      }

      .ant-upload-text {
        color: rgba(0, 0, 0, 0.85);
        font-size: 12px;
        margin: 0;
        padding: 0 16px;
      }
    }

    .ant-divider {
      margin: 24px 0;
    }
  }

  .download-button {
    padding: 0;
    margin: 10px 0;
    text-decoration: underline;
    color: rgba(0, 0, 0, 0.6);
    display: flex;
    align-items: center;
    gap: 8px;

    &:hover {
      color: rgba(0, 0, 0, 0.8) !important;
    }
  }
}