@import "../../../styles/variables";

.root {
    :global {
        .ant-modal-mask {
            background: rgba(255, 255, 255, 0.55);
            backdrop-filter: blur(10px);
            -webkit-backdrop-filter: blur(10px);
        }
    }

    .modal {
        width: max-content !important;
        border-radius: 30px;

        :global {
            .ant-modal-close {
                border-radius: 50%;
            }

            .ant-modal-content {
                width: 400px;
                box-shadow: none;
                padding: 60px 0px 40px 0px;

                @media screen and (max-width: 400px) {
                    width: 100%;
                }
            }

            .ant-modal-body {
                display: flex;
                flex-direction: column;
                justify-content: space-evenly;
                align-items: center;
                gap: 30px;

                // padding: 40px;
                svg {
                    height: 42px;
                    width: 42px;
                }
            }

            .ant-otp {
                width: 75%;

                @media screen and (max-width: 400px) {
                    width: 80%;
                }

                input {
                    font-size: 18px;
                }
            }
        }

        .text {
            color: #18171A;
            font-size: 20px;
            text-align: center;
            width: 100%;
        }

        .error {
            color: var(--antd-error-color);
            margin-top: -20px;
        }
    }
}