.tag {
  margin-bottom: 15px;
  background-color: rgb(167, 161, 161);
  color:grey !important;
  margin-left:-8px
}

.imagePreview{
  display: none;
}
.programForm{
  width:80%
}
.steps-divider{
  display: flex;
clear: both;
width: calc(100% + 52px);
min-width: 100%;
margin: 10px -26px 0px -26px;
}

