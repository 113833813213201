.button {
  width: 100%;
  justify-content: center;
  background: #FAFAFA !important;
  box-shadow: none !important;
  color: var(--antd-primary-color) !important;
  border: 1px solid #F0F0F0 !important;
  padding-top: 16px !important;
  padding-bottom: 16px !important;
  margin-bottom: 25px;
  span {
    color: var(--antd-primary-color);
  }
}

.add-media {
  margin-top: 4px;
  padding: 6px 17px;
  width: 100%;
  display: flex;
  justify-content: center;
}