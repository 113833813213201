@import "../../../../styles/variables";

.modal {
  border-radius: 8px;
  padding-bottom: 0px;
  :global {
    .ant-modal-content {
      padding: 0;
      .ant-modal-header {
        border-bottom: solid 1px #e5e5e5;
        padding: 15px 24px 15px 24px;
      }
      .ant-modal-footer {
        border-top: solid 1px #e5e5e5;
        padding: 15px 24px 15px 24px;
        display: flex;
        place-content: flex-end;
      }
      .ant-modal-body {
        padding: 0px 24px;
      }
    }
  
  }
  h3 {
    font-family: var(--font-family-secondary);
    font-weight: 500;
    font-size: 16px;
    letter-spacing: -0.02em;
    margin-top: 20px;
    margin-bottom: 23px;
  }
}
