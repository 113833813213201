.homepageCard {
    background: #FFFFFF;
    border-radius: 10px;
    height: 100%;
    padding: 2.25rem;
}

.homepageCard .icon {
    display: block;
    flex: 0 0 8.4%;
    max-width: 8.4%;
}

.homepageCard .content {
    display: block;
    flex: 0 0 66.6%;
    max-width: 66.6%;
    padding: 0px 5px;
}

.homepageCard .disable .title {
    color: #141414 !important;
    opacity: 50%;
}

.homepageCard .action {
    display: block;
    flex: 0 0 25%;
    max-width: 25%;
}

.homepageCard .title {
    color: #000000 !important;
    font-size: 18px !important;
    line-height: 24px;
    font-weight: 500;
    font-family: var(--font-family-secondary);
}

.homepageCard .description {
    color: #8c8c8c !important;
    font-size: 14px !important;
    line-height: 22px;
    font-weight: 400;
    font-family: var(--font-family-secondary);
}

.homepageCard button {
    height: max-content;
    margin-top: auto;
    font-family: var(--font-family-secondary);
    font-size: 16px;
    width: 100%;
}

.homepageCard button div {
    background-color: #D9D9D9;
    font-size: 24px;
    height: 35px;
    width: 35px;
    border-radius: 50%;
}

.card-icon {
    height: 2rem;
    width: 2rem;
}

@container homepage (max-width: 767px) {
    .homepageCard .icon {
        display: block;
        flex: 0 0 8.4%;
        max-width: 8.4%;
    }

    .homepageCard .content {
        display: block;
        flex: 0 0 57%;
        max-width: 57%;
    }

    .homepageCard .action {
        display: block;
        flex: 0 0 33.6%;
        max-width: 33.6%;
    }
}

@container homepage (max-width: 576px) {
    .homepageCard .icon {
        display: flex;
        flex: 0 0 100%;
        max-width: 100%;
        justify-content: center;
        margin: 5px 0;
    }

    .homepageCard .content {
        display: flex;
        flex: 0 0 100%;
        max-width: 100%;
        text-align: center;
        flex-direction: column;
        margin: 5px 0;
    }

    .homepageCard .action {
        display: flex;
        flex: 0 0 100%;
        max-width: 100%;
        justify-content: center;
        margin: 5px 0;
    }
}