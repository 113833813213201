.heading {
  img {
    border-radius: 10px;
    object-fit: cover;
  }
  h1 {
    margin-bottom: 10px !important;
  }
  p {
    color: var(--color-brand-sub-heading-text);
    font-size: 13px;
  }
}
.action-button {
  display: flex;
  align-items: center;
  gap: 6px;
  color: var(--color-brand-primary) !important;
  &:hover {
    color: var(--color-brand-text) !important;
  }
}

.tab-label {
  display: flex;
  align-items: center;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: -0.02em;
  & > div {
    display: flex;
    margin-right: 10px;
  }
}

.tabs {
  :global(.action-buttons) {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 19px;
  }
}

.merchant-logo {
  position: absolute;
  right: 16px;
  top: 191px;
  img {
    border-radius: 100% !important;
    border: solid 3px #fff;
  }
}

.title {
  :global {
    .ant-space-item div {
      margin-bottom: 0 !important;
    }
  }
}

.tag-wrapper {
  margin-bottom: 13px;
}

.pass-wrapper {
  &.active {
    .status-band {
      background: #f6ffed;
      border-top: 1px solid #b7eb8f;
      border-bottom: 1px solid #b7eb8f;
    }
  }
  .status-band {
    background: #f5f5f5;
    margin-top: -42px;
    border-top: 1px solid #d9d9d9;
    border-bottom: 1px solid #d9d9d9;
    margin-left: calc(var(--content-area-padding) * -1);
    margin-right: calc(var(--content-area-padding) * -1);
    padding: 10px var(--content-area-padding) 10px var(--content-area-padding);
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 33px;
    button {
      padding: 3px 20px;
      color: #fff;
      span {
        margin: 0;
        color: #fff;
      }
    }
    span {
      margin-left: 10px;
      color: var(--color-form-error);
    }
    span[role="img"] {
      margin-left: 0;
    }
  }
}



