#gradient-canvas {
  --gradient-color-1: #1acee9;
  --gradient-color-2: #982ff7;
  --gradient-color-3: #f34a9d;
  --gradient-color-4: #ee8733;
  z-index: 0;
  position: absolute;
  width: 100vw;
}

.landing-wrapper {
  min-height: 100vh;
  min-width: 100vw;
  z-index: 2;
  position: relative;
}

.user-control-wrapper {
  padding: 60px 0px;
  flex-grow: 1;
}
.user-control {
  font-family: "Inter", sans-serif;
  /* margin:0px auto; */
  width: 470px;
  text-align: center;
  border-radius: 15px;
  background-color: rgba(13, 17, 23, 0.75);
  z-index: 1;
  position: relative;
}

.user-control .close-btn {
  background: none;
  position: absolute;
  top: 15px;
  right: 20px;
  height: 20px;
  width: 20px;
  font-size: 20px;
}

.user-control .username {
  color: #ccdae7;
  font-size: 22px;
}

.user-control h1 {
  color: #ccdae7;
  font-family: var(--font-family-secondary);
  font-size: 36px !important;
  font-weight: bold;
  text-transform: none;
  letter-spacing: -2px;
}

.user-control .control-btns button {
  padding: 10px 20px;
  font-size: 22px;
  color: #0d1117;
  color: white;
  background: none !important;
  border-bottom: 2px solid transparent;
}

.user-control .control-btns .active {
  border-bottom: 2px solid #0d1117;
  border-bottom: 2px solid white;
}

.user-control .social-buttons {
  display: inline-flex;
  margin: 10px 0px;
}
.user-control .social-buttons button {
  background: #fff;
  box-shadow: 1px 2px 6px -1px rgb(0 0 0 / 34%);
  padding: 12px 34px;
  display: flex;
  align-items: center;
  border-radius: 15px;
}
.user-control .social-buttons button .iconStyle {
  margin-right: 18px;
}
.user-control .social-buttons button .iconStyle svg {
  width: 30px;
  height: 30px;
}
.user-control .social-buttons button .googleText {
  padding: 0;
  color: #727272 !important;
}

.user-control p {
  color: #a7b5c4;
}

.user-control p a {
  color: #3aecba;
}
.user-control .signup-text {
  color: #ccdae7;
  font-size: 18px;
}

.google-icon {
  background-color: transparent;
}

.google-icon svg {
  height: 30px;
  width: 30px;
}

.login-input {
  background: rgba(0, 0, 0, 0.4) !important;
  border-color: rgba(255, 255, 255, 0.3);
  color: #fff;
}
.login-input::placeholder {
  color: #fff;
}

.form-login .form-login-email-field .ant-form-item-control {
  margin: -4px 0px;
}

.form-login .ant-form-item-control-input {
  text-align: left;
}

.form-login .ant-form-item-control-input span {
  color: #fff;
}

@media (max-width: 470px) {
  .user-control {
    width: 90%;
  }
  .user-control .social-buttons button {
    padding: 12px 20px;
  }
  .user-control .social-buttons button .googleText {
    font-size: 18px;
  }
  .user-control .social-buttons button .iconStyle {
    margin-right: 7px;
  }
}

@media (max-width: 420px) {
  .user-control {
    padding: 35px 25px !important;
  }
}

@media (max-width: 376px) {
  .user-control h1 {
    font-size: 28px;
  }

  .user-control .control-btns button {
    font-size: 16px;
  }

  .user-control .social-buttons button {
    padding: 10px 16px;
  }

  .user-control p {
    font-size: 14px;
  }
}
