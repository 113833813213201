.benefits-card {
  text-align: center;
  .wrapper {
    margin: -24px;
    a {
      display: block;
      padding: 42px;
    }
    :global{
      .m-icons{
        font-size: 40px;
        color: #8E00F4;
        display: block !important;
        margin-bottom: 18px;
      }
    }
    .image {
      margin-bottom: 18px;
    }
    h3 {
      font-family: var(--font-family-secondary);
      font-weight: 400;
      font-size: 22px;
      line-height: 32px;
      color: rgba(20, 20, 20, 0.85);
      margin-bottom: 5px;
    }
    p {
      font-family: var(--font-family-secondary);
      font-weight: 400;
      font-size: 15px;
      line-height: 22px;
      color: var(--color-brand-sub-heading-text);
    }
  }
}
