.more-button {
  display: inline-block !important;
  margin-left: 10px;
}

.full-text-modal {
  :global {
    .ant-modal-footer {
      .ant-btn:first-of-type {
        display: none;
      }
    }
  }
}

.text {
  display: inline;
}
