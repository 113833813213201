.ebook-upload {
  :global(.ant-upload .ant-upload-btn) {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
    :global(.ant-upload-drag-container) {
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      :global(.ant-upload-hint) {
        margin-left: 10px;
      }
      :global(.fileName) {
        height: 100%;
        width: 100%;
        p {
          height: 100%;
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: flex-start;
          text-align: left;
          color: var(--color-brand-button-color) !important;
        }
        div {
          margin: 0px 5px;
          :global(.m-icons) {
            height: 100%;
            vertical-align: middle;
            color: rgba(0, 0, 0, 0.45);
            font-size: 16px;
          }
        }
      }
    }
  }
}

.tag {
  margin-bottom: 10px;
}

.preview-container {
  position: relative;
  height: 180px;
  width: 230px;
  margin: 20px 0px;
  border-radius: 7px;
  button {
    border: 1px dashed var(--color-brand-default-border-color);
    border-radius: 7px;
    color: var(--color-push-notification-heading);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    img {
      height: 100%;
      width: 100%;
      object-fit: cover;
      border-radius: 7px;
      object-fit: cover;
    }
    p {
      font-size: 18px;
    }
    .preview-icon {
      height: 100%;
      width: 100%;
      top: 0;
      left: 0;
      position: absolute;
      display: flex;
      justify-content: center;
      align-items: center;
      span {
        font-size: 42px;
        color: var(--color-push-notification-heading);
      }
    }
  }
}

.hover {
  button {
    &:hover {
      background: rgba(0, 0, 0, 0.2);
    }
  }
}
