.homepage {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: #f5f5f5;
  margin: -46px;
  padding: 46px;
  min-height: 100vh;
  contain: layout;
  container-type: inline-size;
  container-name: homepage;

  h1 {
    margin-top: 1rem !important;
    font-family: var(--font-family-secondary);
    font-size: 20px;
    font-weight: 500;
    line-height: 24px;
  }

  h2 {
    margin-bottom: 3rem !important;
    font-family: var(--font-family-secondary);
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    color: #8c8c8c;
  }
}

.row {
  margin-bottom: 1rem !important;
  display: block;
  width: 100%;
  max-width: 825px;
  margin-top: .5rem !important;
}

@container homepage (max-width: 767px) {
  .row {
    max-width: 650px;
  }
}

@container homepage (max-width: 576px) {
  .row {
    max-width: 425px;
  }
}