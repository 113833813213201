@import "../../../../../styles/mixins";

.radio {
  border: solid 1px #D9D9D9;
  padding: 7px 11px;
  @include border-radius(5px);
  justify-content: center;
  &:hover {
    border-color: var(--antd-primary-color);
  }
  &:global(.ant-radio-wrapper-checked) {
    background-color: #F5F5F5;
    border-color: var(--antd-primary-color);
    color: var(--antd-primary-color);
  }
}