.template-heading {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;

  h4 {
    font-size: 14px;
    color: #595959d9;
    margin-bottom: 0;
  }

  .template-radio {
    label {
      font-size: 12px;
      padding-inline: 26px;
    }
  }
}

.template-card {
  box-shadow: 0px 15px 25px rgba(9, 9, 9, 0.09);

  img {
    max-height: 1384px;
    object-fit: contain;
  }

  :global {
    .ant-card-body {
      padding: 0;
    }
  }

  .placeholder-wrapper {
    max-width: 648px;
    position: relative;
    margin: 0 auto;

    .hero-placeholder {
      position: absolute;
      width: 45%;
      height: 4.5%;
      background: rgba(255, 255, 255, 1);
      left: 7.5%;
      top: 6.2%;

      h2 {
        // font-family: var(--font-family-base);
        font-size: 28px;
        font-weight: 800;
        text-transform: uppercase;
        letter-spacing: -0.3px;
        line-height: 26px;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
      }

      p {
        font-size: 10px;
        line-height: 12px;
        margin-top: 5px;
        -webkit-box-orient: vertical;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: normal;
      }
    }
  }
}

.numbers-input {
  :global {
    .ant-input-group-wrapper {
      &+input {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        text-align: right;
      }

      .ant-input-group-addon {
        border-radius: 0;
        border-right: 0;
        border-left: 0;
        background: none;
      }
    }
  }
}

.menu-options {
  display: flex;
  flex-direction: column;
  gap: 10px;

  :global {
    .ant-checkbox-wrapper {
      &+.ant-checkbox-wrapper {
        margin-inline-start: 0px;
      }
    }
  }
}

.sort-option {
  margin-bottom: 15px;

  .custom-panel {

    :global {
      .ant-collapse-header {
        border-radius: 5px;
        padding: 8px 12px;
        display: flex;
        justify-content: space-between;
        cursor: default;

        .ant-collapse-expand-icon {
          display: none;
        }

        .ant-collapse-header-text {
          display: flex;

        }
      }
    }
  }
}

.override-option {
  display: flex;
  align-items: center;
  gap: 15px;
  margin-bottom: 20px;

  :global {
    .ant-form-item {
      margin-bottom: 0;
    }
  }
}

.promotional {
  :global {
    margin-bottom: 10px;
    .ant-form-item-row {
      align-items: center;
      flex-direction: row !important;

      .ant-form-item-label {
        padding-bottom: 0px !important;
      }

      .ant-form-item-control {
        width: max-content !important;

        .ant-form-item-control-input {
          width: max-content;
        }
      }
    }
  }
}