.user-modal {
    :global {
        .ant-modal-content {
            .ant-modal-body {
                padding-bottom: 40px;
            }

            .ant-form {
                padding: 20px 20px 0px 20px;
                max-width: 400px;
                margin: 0 auto;
            }
        }

        :local(.create-user-btn) {
            margin: 20px 0px 20px auto
        }
    }
}