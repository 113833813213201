.header {
  margin-bottom: 30px;
  .head {
    display: flex;
    justify-content: space-between;
    align-items: center;
    h1 {
      font-family: var(--font-family-secondary);
      font-weight: 500;
      font-size: var(--font-size-pgae-heading);
      line-height: 32px;
      text-transform: none;
      margin-bottom: 0;
    }
  }
}