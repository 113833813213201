.playlist {
  // width: 100%;
  height: 70px;
  background: rgb(228, 228, 228);
  margin: 0 -24px;
  display: flex;
  align-items: flex-start;
  position: relative;
  padding: 10px;
  gap: 10px;
  overflow-x: scroll;
  &.audio {
    height: 50px;
    button {
      width: 50px;
      min-width: 50px;
    }
  }
  button {
    width: 100px;
    height: 100%;
    min-width: 100px;
    background: #fff;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}

.modal {
  :global {
    .ant-modal-header {
      margin-bottom: 0;
    }
  }
}