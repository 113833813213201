:root {
  /* ---- Main colors ---- */
  --color-brand-primary:                      #C700EB;
  --color-brand-secondary:                    #8E00F4;
  --color-brand-text:                         #222222;
  --color-brand-navigation:                   #0C1017;
  --color-brand-navigation-divider:           #202329;
  --color-brand-navigation-text-color:        #AAAAAA;
  --color-brand-navigation-sub-menu:          #202329;
  --color-brand-sub-heading-text:             #8C8C8C;
  --color-brand-button-color:                 #9B43FD;
  --color-brand-form-border-color:            #C700EB;
  --color-brand-default-border-color:         #D9D9D9;
  --antd-primary-color:                       #9B43FD;
  --antd-error-color:                         #ff4d4f;
  --antd-input-border-color:                  #e5e5e5;
  --antd-default-color:                       #F0F0F0; 
  --color-brand-bg-content-area:              #ffffff;
  --color-brand-button-text-color:            #ffffff;
  --color-form-wrapper-header:                #FAFAFA;
  --color-form-error:                         #FF4F00;
  --color-indicator-background:               #0094FF;
  --color-push-notification-highlight:        #40A9FF;
  --color-push-notification-heading:          #595959;
  --color-drag-icon:                          #BFBFBF;
  --color-storefront-title:                   #141414;
  --color-storrefront-purple:                   #45319D;
  --perfix: "";

  /* ---- Typography ---- */
  --font-family-base:                         "RobotoCondensed-Regular", "sans-serif";
  --font-family-secondary:                    "Inter", sans-serif;
  --font-family-lato:                         "Lato-Regular";
  --font-family-lato-semi-bold:               "LatoSemibold";
  --font-family-roboto:                       'Roboto', sans-serif;
  --font-family-poppins:                       "Poppins-Regular";

  /* ---- Font Size ---- */
  --font-size-global:                         16px;
  --font-size-section-heading:                22px;
  --font-size-meta:                           12px;
  --font-size-breadcrumb:                     14px;
  --font-size-pgae-heading:                   24px;
  --font-size-onboarding-form-heading:        20px;

  /* ---- Spacing ---- */
  --content-area-padding:                     46px;
}
