.note {
  border-top: solid 1px #e5e5e5;
  margin: 0 -24px;
  padding: 13px 24px 5px 24px;
  font-family: var(--font-family-secondary);
  color: var(--color-brand-sub-heading-text);
  a {
    color: var(--antd-primary-color);
    font-weight: 500;
    &:hover {
      color: var(--color-brand-text);
    }
  }
}