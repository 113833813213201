.select-program-wrapper {
  margin-top: 30px;

  .heading {
    align-items: center;

    h3 {
      font-size: 16px !important;
      font-weight: 500;
      letter-spacing: -0.02em;
      margin-bottom: 0 !important;
    }

    &.disabledRow {
      pointer-events: none;
      display: none;
    }
  }

  .description {
    font-family: var(--font-family-secondary);
    font-size: 14px;
    color: rgba(140, 140, 140, 0.85);
    letter-spacing: -0.02em;
    margin-bottom: 27px !important;

    &.disabledRow {
      pointer-events: none;
      display: none;
    }
  }

  .program-slider {
    width: calc(100% + 102px);
    overflow-x: auto;
    display: flex;
    margin-left: -46px;
    gap: 20px;
    padding: 10px 0px 40px 46px;
    margin-bottom: 40px;

    &::-webkit-scrollbar {
      display: none;
    }

    .program-card {
      width: 100%;
      max-width: 250px;
      flex: none;

      &:last-child {
        margin-right: 30px;
      }

      :global {
        .ant-card-meta-title {
          margin-bottom: 10px;
        }

        .ant-card {
          border: 1.5px solid transparent;
        }

        .selected-card {
          border-color: var(--color-brand-button-color);
        }
      }
    }
  }

  .blockchain-select-row {
    margin-bottom: 40px;

    .blockchain-select {
      width: 30%
    }
  }

  .collection-checkbox {
    span {
      &:last-child {
        display: flex;
        align-items: center;
      }

      :global {
        .ant-tag {
          margin-left: 10px;
        }
      }
    }
  }

  .collection-row {
    margin-top: 40px;
    margin-bottom: 20px;

    :global {
      .ant-col {
        margin-bottom: 20px;
        padding-bottom: 20px;
        border-bottom: solid 1px #eeeeee;
      }
    }

    &.disabledRow {
      display: none;
    }
  }

  :global {
    .ant-pagination {
      margin-top: 0px !important;
    }
  }
}