.table {
  font-family: var(--font-family-secondary);
  border-top: 1px solid rgba(5, 5, 5, 0.09);
  :global {
    .ant-table {
      margin-bottom: 20px;
    }
    thead.ant-table-thead th {
      font-weight: 500;
      font-size: 13px;
      color: var(--color-brand-sub-heading-text);
    }
    thead.ant-table-thead th:first-child {
      border-start-start-radius: 0px !important;
    }
    thead.ant-table-thead th:last-child {
      border-start-end-radius: 0px !important;
    }
    tbody.ant-table-tbody td {
      font-weight: 400;
      font-size: 13px;
    }
    th.ant-table-selection-column {
      width: 32px
    }
  }
}