.btn-green{
    background-color: #00AB66;
    border-radius: 5px;
    color:white
}
.btn-red{
  background-color: rgb(181, 14, 14);
  border-radius: 5px;
  color:white
}
.black-text{
  color:black
}