.deleteIcon {
  color: var(--color-form-error);
}

.info {
  margin-bottom: 20px !important;
  color: var(--color-brand-sub-heading-text);
}

.heading {
  margin-bottom: 10px !important;
}

.container {
  margin-bottom: 10px !important;
}