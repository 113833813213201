.user-modal {
    padding-bottom: 0px;

    :global {
        .ant-modal-content {
            .ant-modal-body {
                padding: 0px;
                padding-bottom: 45px;

                h3 {
                    margin: 0 0 5px 0;

                    &:nth-of-type(2) {
                        margin-bottom: 15px;
                    }
                }

                .ant-tabs {
                    .ant-tabs-nav {
                        margin-bottom: 0px !important;

                        .ant-tabs-nav-wrap {
                            padding-left: 24px;
                        }
                    }

                    .ant-tabs-content-holder {
                        padding: 0px 24px;
                    }
                }

                .ant-form {
                    padding: 20px 20px 0px 20px;
                    width: max-content;
                    max-width: 400px;
                    margin: 0 auto;

                    .ant-input-affix-wrapper {
                        margin: 0px;
                        // width: 250px;
                    }
                }

                .ant-result {
                    width: 400px;
                    margin: 0 auto;

                    button {
                        margin: 0 auto;
                    }
                }
            }
        }

        .ant-input-affix-wrapper {
            max-width: 500px;
            width: 100%;
            margin: 30px 0px;
        }
    }

    .bulk-delete-container {
        padding-top: 20px;
        width: 100%;
    
        :global {
            .ant-form {
                padding: 0px !important; 
                width: auto !important;
                max-width: unset !important;
            }
            .ant-upload-drag {
                margin: 20px 0;
                border: 1px dashed #d9d9d9;
                border-radius: 8px;
                cursor: pointer;
                transition: border-color 0.3s;
                background: #fafafa;
                
                &:not(.ant-upload-disabled):hover {
                    // border-color: #1890ff;
                }
                
                &.ant-upload-disabled {
                    cursor: not-allowed;
                    
                    .ant-upload-drag-icon .anticon {
                        color: rgba(0, 0, 0, 0.25);
                    }
                    
                    .ant-upload-text {
                        color: rgba(0, 0, 0, 0.25);
                    }
                }
                
                .ant-upload-drag-container {
                    .anticon {
                        color: rgba(0, 0, 0, 0.25);
                        // color: #1890ff;
                        font-size: 14px;
                        // transition: color 0.3s;
                    }
                }
                
                .ant-upload-text {
                    color: rgba(0, 0, 0, 0.85);
                    font-size: 12px;
                    margin: 0;
                    padding: 0 16px;
                }
            }
    
            .ant-divider {
                margin: 24px 0;
            }
        }
    
        .download-button {
            padding: 0;
            margin: 10px 0;
            text-decoration: underline;
            color: rgba(0, 0, 0, 0.6);
            display: flex;
            align-items: center;
            gap: 8px;
    
            &:hover {
                color: rgba(0, 0, 0, 0.8) !important;
            }
        }
    
        .button-container {
            display: flex;
            gap: 20px;
            margin-top: 40px;
    
            button {
                flex: 1;
            }
        }
    }
}

.icon {
    cursor: pointer;
    width: max-content;
    margin: 0 auto;
}

.table-header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .ant-input-affix-wrapper {
        margin: 0;
    }
}

.bulk-delete-container {
    padding-top: 20px;
    width: 100%;

    :global {
        .ant-upload-drag {
            margin: 20px 0;
            border: 1px dashed #d9d9d9;
            border-radius: 8px;
            cursor: pointer;
            transition: border-color 0.3s;
            background: #fafafa;
            
            &:not(.ant-upload-disabled):hover {
                // border-color: #1890ff;
            }
            
            &.ant-upload-disabled {
                cursor: not-allowed;
                
                .ant-upload-drag-icon .anticon {
                    color: rgba(0, 0, 0, 0.25);
                }
                
                .ant-upload-text {
                    color: rgba(0, 0, 0, 0.25);
                }
            }
            
            .ant-upload-drag-container {
                .anticon {
                    color: rgba(0, 0, 0, 0.25);
                    // color: #1890ff;
                    font-size: 14px;
                    // transition: color 0.3s;
                }
            }
            
            .ant-upload-text {
                color: rgba(0, 0, 0, 0.85);
                font-size: 12px;
                margin: 0;
                padding: 0 16px;
            }
        }

        .ant-divider {
            margin: 24px 0;
        }
    }

    .download-button {
        padding: 0;
        margin: 10px 0;
        text-decoration: underline;
        color: rgba(0, 0, 0, 0.6);
        display: flex;
        align-items: center;
        gap: 8px;

        &:hover {
            color: rgba(0, 0, 0, 0.8) !important;
        }
    }

    .button-container {
        display: flex;
        gap: 20px;
        margin-top: 40px;

        button {
            flex: 1;
        }
    }
}