.audit-admin-view{
    margin-top:3rem;
    margin-bottom:1rem;
}
.audit-heading{
    margin-bottom: 2rem;
}
.selected {
    background: rgba(160, 60, 204, 0.2);
    border-radius: 5px;
}
.row-header {
    height: 50px;
    font-size:20px;
    color: black !important;
    font-weight: bold;
    font-family: inherit;
    display: flex;
    align-items: center;
}


  #controlled-tab-example-tab-permissions {
    height: 51px;
    font-size:20px;
    color: black !important;
    font-weight: bold;
    font-family: inherit;
    display: flex;
    align-items: center;
  }

  #controlled-tab-example-tab-members {
    height: 51px;
    font-size:20px;
    color: black !important;
    font-weight: bold;
    font-family: inherit;
    display: flex;
    align-items: center;
  }

  #controlled-tab-example-tab-merchants {
    height: 51px;
    font-size:20px;
    color: black !important;
    font-weight: bold;
    font-family: inherit;
    display: flex;
    align-items: center;
  }