.upload-modal {
    min-height: 50vh !important;
    height: 50vh !important;
    :global {
        .ant-modal-content {
            .ant-modal-body {
                padding: 0px;
                padding-bottom: 45px;

                .ant-tabs {
                    .ant-tabs-nav {
                        .ant-tabs-nav-wrap {
                            padding-left: 24px;
                        }
                    }
                    .ant-tabs-content-holder{
                        padding: 0px 24px;
                    }
                }
            }
        }
    }
}