.copy-to-clip-board.full-card {
  background: rgba(249, 248, 248, 0.71);
  border: 1px solid rgba(0, 0, 0, 0.12);
  border-radius: 6px;
  padding: 8px 20px;
  width: 100%;
  color: #706e6d;
  font-weight: 600;
  font-size: 15px;
  display: flex;
  align-items: center;
}
.copy-to-clip-board.only-image {
  display: inline-block;
}
.copy-to-clip-board.only-image img {
  margin-right: 0;
}
.copy-to-clip-board img {
  margin-right: 10px;
  margin-top: -4px;
}
.copy-to-clip-board button {
  background: none;
}
.copy-to-clip-board.full-card .address-link {
  font-weight: 600;
  color: #69a1f6;
}
.copy-to-clip-board.full-card .address-link:hover {
  color: #706e6d;
}