.audit-admin-view{
    margin-top:3rem;
    margin-bottom:1rem;
}
.audit-heading{
    margin-bottom: 2rem;
}
.table-para{
color:black
}

.log-level-color-debug{
    color:green
}
.log-level-color-error{
    color:red
}

