.metabaseReports {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: -46px;
  padding: 46px;
  min-height: 100vh;
  contain: layout;
  container-type: inline-size;
  container-name: metabaseReports;

  h1 {
    margin-top: 1rem !important;
    font-family: var(--font-family-secondary);
    font-size: 20px;
    font-weight: 500;
    line-height: 24px;
  }

  h2 {
    margin-bottom: 3rem !important;
    font-family: var(--font-family-secondary);
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    color: #8c8c8c;
  }
}

@container metabaseReports (max-width: 767px) {
  .row {
    max-width: 650px;
  }
}

@container metabaseReports (max-width: 576px) {
  .row {
    max-width: 425px;
  }
}