.tier-stepper-container{
  display: flex;
   margin: 0px -26px 0px -26px; 
}
.tierCardContainer{
  display: flex;
  background-color: #f5f5f5;
  align-items: center;
  flex-flow: column;
  overflow: scroll;
  height: 550px;
  padding-bottom: 2rem;
  }  
  
  .tierCard{
  width:60%;
  display: flex;
  align-items: center;
  justify-content: center;
  }
  
  .addTierCard{
  background-color:#ffffff ;
  padding: 1rem;
  display: flex;
  flex-flow: column;
  height: 550px;
  }

  .tierCardsHeading{
    position: absolute;
    left:10px;
    top:1.5rem;
    font-size: 1.2rem; 
    margin-left: 14%;
   }
  
   .tierCardsList{
     margin-top: 4rem;
   }
   
   .passHeading{
   display:flex;
   }
   
 .tierCardActive{
   border: 2px solid #902ffd;
   border-color: #902ffd;
 
   }
   .tierCardError{
     border: 1px solid #b80336 !important;
     border-color: #b80336 !important;
   
   }
    .tierSingleCard{
    width:15rem;
    height:6rem;
    margin:.4rem
    }
    .tierCardIcon{
     height: 1.7rem;
     width:1.7rem;
     border-radius: 50%;
     padding:5px;
     background-color: #57cc99;
     color:#ffffff;
     display:block;
     margin-top:0.2rem;
     font-size: 1.1rem;
   
    }
    .tierCard{
      width:15rem !important;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: .3rem;
      background-color: #f5f5f5;
      border: 2px #57cc99 dashed;
      height: 6rem;
    }
    .tierFormContainer {
      display: flex;
      background-color: #f5f5f5;
      align-items: center;
      flex-flow: column;
      overflow: scroll;
      height: 750px;
      
      }  
      .tierCardActive {
        border: 1px solid #902ffd;
        border-color: #902ffd;
      }
      
      .add-tier-card-row{
        display: flex;
         align-items: center;
          justify-content: center;
           flex-flow: column;
      }
       .deleteButton{
    color: red;
    margin-left: 2rem;
    font-size: 1.2rem;
   }
  .actionButtonContainer{
    display: flex;
  }
  .tier-single-card-body{
    display: flex; 
    align-items: center; 
    justify-content: center;
     flex-flow: column
  }