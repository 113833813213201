@import "../../../../styles/mixins";

.tag {
  @include border-radius(100px);
  font-weight: 500;
  margin-right: 4px;
  display: inline-flex;
  align-items: center;
  &.color {
    border: none;
    background-color: #F7F0FE;
    color: var(--color-brand-primary);
  }
  &.disabled {
    background-color: #F0F0F0;
    color: #8C8C8C;
  }
}