.header-new {
  background-color: rgb(13, 17, 23, 0.95);
  z-index: 3;
  position: static;
  height: 68px;
  padding: 0px;
}

.header-new nav a:hover {
  color: #982ff7;
}

.header-new .container {
  padding: 0px 35px;
}
