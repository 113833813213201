.card {
  box-shadow: none;
  position: relative;

  :global(.copy-to-clip-board) {
    position: absolute;
    right: 15px;
    top: 15px;
    width: 32px;
    height: 32px;
    padding: 8px;
  }

  h2 {
    font-size: 17px;
    color: var(--color-storefront-title);
    margin-bottom: 20px !important;
  }

  h3 {
    font-size: 15px;
    color: var(--color-storefront-title);
  }

  :global {
    .ant-card-body {
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
  }
}