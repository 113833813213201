.tier-thresholds {
    display: flex;
    flex-wrap: wrap;
}

.tier-card {
    box-sizing: border-box;
    margin-bottom: 22px;
    margin-right: 22px;
    padding: 22px 34px 22px 34px;
    width: 264px;
    height: 92px;

    background: #FFFFFF;

    box-shadow: 0px 16px 40px rgba(9, 9, 9, 0.05);
    border-radius: 10px;

    font-family: var(--font-family-secondary);
    font-size: var(--font-size-global);
    color: var(--color-brand-text)
}

.point-threshold {
    background: #F9F0FF;
    border-radius: 9px;
    padding: 5px 10px 5px 5px;
    font-family: var(--font-family-secondary);
    color: var(--color-brand-secondary);
    font-size: var(--font-size-breadcrumb);
    display: inline-block;
    width: auto;
}

.add-icon {
    font-size: 16px !important;
    margin-right: 5px;
}

.earn-points {
    background: #FFFFFF;
    box-shadow: 0px 0px 40px rgba(9, 9, 9, 0.05);
    border-radius: 10px;

    .header {
        padding: 25px 30px 0px 30px;
    }


    h2 {
        font-family: var(--font-family-secondary);
        font-size: var(--font-size-global);
        color: var(--color-brand-sub-heading-text);
        line-height: 22px;
    }

    h3 {
        font-family: var(--font-family-secondary);
        font-size: var(--font-size-meta);
        color: var(--color-brand-sub-heading-text);
        line-height: 22px;
    }

    h4 {
        font-family: var(--font-family-secondary);
        font-size: var(--font-size-breadcrumb);
        color: #141414;
        line-height: 18px;
        margin: 0px;
    }

    p {
        font-family: var(--font-family-secondary);
        font-size: var(--font-size-meta);
        color: var(--color-brand-sub-heading-text);
        line-height: 18px;
    }

    th {
        background-color: #F9F0FF !important;
        color: #141414;
        font-size: var(--font-size-meta);
        font-family: var(--font-family-secondary);
    }

    .buttons {
        margin-top: 25px;
        margin-right: 30px !important;
    }

    img {
        width: 31px;
        height: 31px;
    }

    .streak-box {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: 6px 8px;
        gap: 8px;

        width: 152px;
        height: 46px;

        background: #FBF4FF;
        border-radius: 2px;

        p {
            font-family: var(--font-family-roboto);
            font-size: 11px;
            line-height: 12px;
        }
        .title {
            color: rgba(20, 20, 20, 0.55);
            font-style: italic;
        }

        .value {
            color: rgba(20, 20, 20, 0.85);
        }

    }

}

.italic {
    font-style: italic;
}

.space-between {
    display: flex;
    justify-content: space-between;
    margin-right:20px;

}

.table-column {
    text-transform: uppercase;
}