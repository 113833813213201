.form-checkbox label {
  margin-bottom: 0;
  display: flex;
  align-items: center;
  position: relative;
}
.form-checkbox input {
  display: none;
}
.form-checkbox input:checked + label::before {
  background: #0079E5;
  border-color: #0079E5;
}
.form-checkbox input:checked + label::after {
  content: '';
  display: block;
  position: absolute;
  top: 4.5px;
  left: 5px;
  width: 5px;
  height: 9px;
  border: solid #fff;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
  transition: all .3s ease;
}
.form-checkbox label::before {
  content: "";
  width: 15px;
  height: 15px;
  border: solid 1px #737376;
  margin-right: 7px;
  border-radius: 2px;
  transition: all .3s ease;
}