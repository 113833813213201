@import '../../../../styles/mixins';

.profile-image-wrapper {
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin-bottom: 14px;
  @include transition(all .3s ease);
  img {
    margin-right: 11px;
    margin-bottom: 14px;
    width: 60px;
    width: 60px;
    object-fit: cover;
    @include transition(all .3s ease);
  }
  p {
    color: var(--color-brand-navigation-text-color);
    font-family: var(--font-family-secondary);
    font-size: 15px;
    font-weight: 500;
  }
  &.collapsed {
    width: 50px;
    overflow: hidden;
    img {
      width: 40px;
      height: 40px;
    }
  }
  .proxy-badge{
    background: var(--color-indicator-background);
    color: var(--color-brand-button-text-color);
    margin-left: 10px;
    padding:2px 10px;
    border-radius:10px;
    font-size: 12px;
  }
}