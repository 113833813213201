.delete-confirmation {
    :global {
        .ant-modal-content {
            .ant-modal-body {
                padding-bottom: 20px;
                p:first-of-type {
                    margin-top: 10px;
                }

                p:last-of-type {
                    margin: 10px 0px !important;
                }

                strong {
                    font-size: 20px;
                }

                input {
                    margin-top: 10px;
                    width: 150px;
                }
            }
        }
    }
}