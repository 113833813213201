.datatable-wrapper .rdt_TableHead {
  font-size: 20px;
  font-family: "Lato-Bold";
  color: #555555;
  font-size: 15px;
  text-transform: uppercase;
  border-top: solid 1px rgba(0,0,0,.12);
}
.datatable-wrapper .rdt_TableHead .rdt_TableHeadRow {
  background: #373737;
  color: #fff;
}
.datatable-wrapper .rdt_TableRow {
  font-size: 16px;
  color: #888888;
  border-bottom: none;
}
.datatable-wrapper .rdt_TableRow:last-child {
  border-radius: 0px 0px 12px 12px;
}

.datatable-wrapper .rdt_TableBody {
  border: 1px solid rgba(0, 0, 0, 0.12);
  border-radius: 0px 0px 12px 12px;
}

.datatable-wrapper .react-select-wrapper {
  width: 100%;
}

/* Contracts Table*/

.datatable-wrapper.contract-table .rdt_TableHead .rdt_TableHeadRow{
  /* background: #f5f5f5; */
  /* color:#4B4B4B; */
  border:none;
}

.datatable-wrapper.contract-table .rdt_TableRow:nth-child(odd){
  background: #f5f5f5; 

  color:#706E6D;
  height:60px;
  border-top:1px solid rgba(0, 0, 0, 0.12);
  
}
.datatable-wrapper.contract-table .rdt_TableRow:nth-child(even){
  background: none;
  color:#706E6D;
  border:none; 
  height:60px;
  /* border-top:1px solid rgba(0, 0, 0, 0.12); */
}

.datatable-wrapper.contract-table .rdt_TableBody{
  border:none;
  /* border-bottom: 1px solid rgba(0, 0, 0, 0.12); */

}

.datatable-wrapper.contract-table .rdt_TableHead{
  border:none;
}

.datatable-wrapper.contract-table .rdt_TableRow:last-child{
  border-radius:0;
}

.datatable-wrapper.contract-table .rdt_TableRow:last-of-type{
  border-bottom:1px solid rgba(0, 0, 0, 0.12);
}

/* Contract NFT Table */
.contract-nft-table .rdt_TableHead .rdt_TableHeadRow{
  background: white;
  color:#4B4B4B;

  border-bottom: 1px solid rgba(0, 0, 0, 0.12)
  
}
.contract-nft-table .rdt_TableHead{
  border-top:none;
}

.contract-nft-table .rdt_TableRow{
  background:white;
  border:none;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}

.contract-nft-table .rdt_TableRow:last-of-type{
  border-bottom:none;
}

.contract-nft-table .rdt_TableBody {
  border:none;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  border-radius: 0px;
}
.contract-nft-table.datatable-wrapper{
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}

/* Conneceted Items Table */
.datatable-wrapper.connected-items-table .rdt_TableCell p{
color: black;
}