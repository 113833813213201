.page-header-heading {
  max-width: 40%;
  font-family: var(--font-family-secondary);

  h3 {
    font-size: 24px !important;
    margin-top: 10px;
  }

  p {
    font-size: 14px !important;
    color: var(--color-brand-sub-heading-text) !important;
    line-height: 18.5px;
  }
}

.page-header-button-cont {
  display: flex;
  align-self: flex-start;

  button {
    font-size: 12px !important;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 35px !important;

    div {
      span {
        vertical-align: middle;
        font-size: 20px;
        margin-right: 5px;
      }
    }
  }
}

.program-tabs {
  display: flex;
  font-size: 18px !important;
  color: var(--color-brand-sub-heading-text) !important;
}

.tab-icon {
  display: flex;
  // color: rgba(20, 20, 20, 0.54);
}

.icon {
  margin-right: 4px;
  font-size: 20px;
}