.account-setup-modal {
  :global {
    .ant-modal-content {
      .ant-modal-header {
        padding: 30px 30px 0px 30px;
        margin-bottom: 0;
        border-bottom: none;
        .ant-modal-title {
          h2 {
            font-size: var(--font-size-onboarding-form-heading);
            font-weight: 500;
          }
          p {
            width: 70%;
            font-size: var(--font-size-breadcrumb);
            color: var(--color-brand-sub-heading-text);
            font-weight: 400;
            margin-top: 5px;
          }
        }
      }
      .ant-modal-body {
        padding: 30px;
      }
      .ant-modal-footer {
        display: none;
      }
      .ant-form-item .ant-form-item-label > label {
        font-weight: 500;
      }

      .label-bg {
        display: flex;
        justify-content: space-between;
        background: #f5f5f5;
        padding: 20px;
        border-radius: 6px;
        margin-bottom: 20px;
      }
      button.get-started-button {
        width: 100%;
        padding: 15px;
        span {
          margin: 0 auto;
        }
      }
      .ant-radio-group {
        width: 100%;
        .ant-radio-wrapper {
          width: 100%;
          svg {
            margin-left: 6px;
          }
        }
      }
    }
  }
}

.onboardingFormTooltip {
  padding-bottom: 15px;
  padding-left: 5px;
}
