.proxy-account-indicator {
  font-family: var(--font-family-secondary);
  background: var(--color-indicator-background);
  color: var(--color-brand-button-text-color);
  position: sticky;
  display: flex;
  justify-content: space-between;
  align-items: center;
  top: 0;
  margin: 0 calc(var(--content-area-padding) * -1) 30px
    calc(var(--content-area-padding) * -1);
  padding: 10px var(--content-area-padding);
  z-index: 9;
  :global {
    .anticon {
      margin-right: 10px;
    }
  }
  button.exit-proxy-button {
    border: 2px solid white;
    padding: 0px 10px;
    color: var(--color-brand-button-text-color);
    &:hover {
      color: var(--color-brand-button-text-color);
      background: var(--color-brand-secondary);
      border-color: var(--color-brand-secondary);
    }
    &:active {
      color: var(--color-brand-button-text-color);
      background: var(--color-brand-secondary);
      border-color: var(--color-brand-secondary);
    }
  }
}
