.full-text-modal {
  :global {
    .ant-modal-footer {
      .ant-btn:first-of-type {
        display: none;
      }
    }
  }
}
.trigger-button {
  display: inline !important;
  margin-left: 10px;
  height: inherit;
  font-family: var(--font-family-secondary);
  font-size: 13px;
  font-weight: 500;
  letter-spacing: -0.02em;
  color: var(--color-brand-sub-heading-text) !important;
  &:hover {
    color: var(--color-brand-primary) !important;
  }
}
