.form-wrapper {
  background: #fff;
  border-radius: 6px;
  box-shadow: 0px 16px 40px rgba(9, 9, 9, 0.05);
  border: solid 1px var(--antd-input-border-color);
  margin-bottom: 38px;
  .header {
    background: var(--color-form-wrapper-header);
    border-radius: 6px 6px 0 0;
    padding: 18px 26px;
    border-bottom: solid 1px var(--antd-input-border-color);
    h2 {
      font-size: 18px;
      font-weight: 500;
      margin-bottom: 6px !important;
      &.no-margin {
        margin-bottom: 0px !important;
      }
    }
    p {
      font-size: 14px;
      font-weight: 400;
      color: var(--color-brand-sub-heading-text);
    }
  }
  .body {
    padding: 16px 26px;
    h4 {
      font-size: 14px;
      font-weight: 500;
      color: rgba(0, 0, 0, 0.88);
      margin-bottom: 3px;
      & + span {
        font-size: 12px;
        font-weight: 500;
        color: rgba(0, 0, 0, 0.45);
      }
    }
  }
}