.title {
    font-family: var(--font-family-secondary);
    font-size: var(--font-size-breadcrumb);
}

.optional {
    font-family: var(--font-family-roboto);
    font-size: var(--font-size-breadcrumb);
    color: rgba(89, 89, 89, 0.25);
}

.action {
    display: flex;

    .card {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 24px 16px;
        // gap: 10px;

        width: 166px;
        height: 110px;

        border-radius: 5px;
        margin: 10px;

        div {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
        }
    }

    img {
        width: 31px;
        height: 31px;
    }

    .selected {
        background: #F5F5F5;
        border-color: var(--color-brand-secondary);
        border-width: 2px;
    }
}

.streak-count {
    display: flex;
    justify-content: center;
    align-items: center;
    .streak-frequency {
        translate: 0px 10px;
    }
}

.split-row {
    display: flex;
    justify-content: space-between;
}

.date {
    width: 49%;
}

.label-img {
    margin-right: 5px;
    object-fit: contain;
}