.benefits-tag{
 
    border-radius: 10px 10px 10px 10px;
    font-size: 13px;
} 
.benefits-icon{
    width:14px !important;
    margin-right:7px;
    height:10px !important
    
}
.add-benefits-text{
    color:#902ffd
}

.attach-all-checkbox{
    display: flex;
    align-items: center;
    margin-left: 7px;
}