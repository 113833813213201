@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700&display=swap");
@import "variables";
@import "./breakpoints";
@import "mixins";

:root {
  --primary-font: "RobotoCondensed-Regular", "sans-serif";
  --secondary-font: "Inter", sans-serif;
}

body {
  margin: 0;
  font-family: "RobotoCondensed-Regular", -apple-system, BlinkMacSystemFont,
    "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.ant-layout {
  font-family: var(--font-family-base) !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.spinner {
  position: absolute;
  height: 100px;
  width: 100px;
  top: 50%;
  left: 50%;
  margin-left: -50px;
  margin-top: -50px;
  background-size: 100%;
}

ul {
  margin: 0px !important;
  padding: 0px !important;
}

header nav li {
  /* margin: 0 16.7px !important; */
}

.mbring-5 {
  margin-bottom: 3rem !important;
}

/* .d-flex h2,.space-60 h2{
  margin-bottom: 0rem !important;
} */
.padding {
  padding: 9.5rem 0;
}

h2,
p {
  margin-bottom: 0rem !important;
}

footer li {
  margin-right: 23.6px;
}

.assetImg {
  width: 100%;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

// td:first-child {
//   width: 15%;
// }

.table> :not(:first-child) {
  border-top: none !important;
}

a {
  text-decoration: none !important;
}

.loginModal span.iconStyle {
  font-size: 30px;
  color: white;
  background: white;
  padding: 1.5px 7px 4px;
}

._loading_overlay_wrapper {
  position: inherit !important;
}

._loading_overlay_overlay {
  position: fixed !important;
  opacity: 0.6 !important;
  height: 100% !important;
  width: 100% !important;
}

._loading_overlay_content {
  font-weight: bolder;
  color: white;
}

.loginLogo {
  display: flex;
  justify-content: space-between;
  margin-left: 105px;
}

.navLoginbtn {
  margin: 11px 104px 0px 0px;
}

.loginModal span.paddingfacebook {
  font-size: 30px;
  color: rgb(66, 103, 178);
  background: white;
  padding: 3px 0px 4px 0px;
}

.carousel .slide img {
  width: 50% !important;
  margin: 0 auto 2rem !important;
}

.carousel .control-dots {
  bottom: 10% !important;
}

.login-carousel .control-dots {
  bottom: 0% !important;
}

.loginModal .login-carousel .logindot {
  margin-bottom: 40px;
}

.mainview {
  height: 430px;
}

.carousel .control-dots .dot {
  width: 10px !important;
  height: 10px !important;
}

.customStyle {
  background-color: red;
  color: white;
  border: none;
  background-color: #4285f4;
  border-color: #4285f4;
  padding-left: 34px;
  padding-right: 34px;
  padding-top: 5px;
}

.googleText {
  color: white !important;
  font-size: 20px;
  display: inline-block;
  padding: 15px;
}

.pointer {
  cursor: pointer;
}

.userContiner {
  padding-top: 39px;
}

.modalStyle .modal-content {
  /* background-color: #f5f5f5;
  box-shadow: #00000029 0px 15px 25px;
  color: navy;
  font-weight: bold;
  border-radius: 25px !important; */
  background-color: #f5f5f5;
  color: navy;
  font-weight: bold;
  border-radius: 25px;
  box-shadow: 0 0.375rem 1rem -0.25rem rgb(0 0 0 / 20%);
}

.modal-header {
  padding-top: 10px !important;
  padding-bottom: 10px !important;
  /* border-bottom: none !important; */
}

.modalPasskit .modal-content {
  height: 100%;
  background-color: #f5f5f5;
  color: navy;
  font-weight: bold;
  box-shadow: #00000029 0px 15px 25px;
  border-radius: 25px !important;
}

.modal-90w {
  max-width: 810px !important;
  height: 700px !important;
}

.approveBtn {
  color: green;
  font-size: 18px;
}

.gridOverflow {
  max-height: 45rem;
  overflow-y: auto;
  overflow-x: hidden;
  margin-bottom: 25px;
}

.txt-align {
  text-align: center;
}

.tab-container {
  background-color: #f5f5f5;
  box-shadow: #00000029 0px 15px 25px;
  position: fixed;
  z-index: 999;
}

.dashbordFooter {
  padding: 30px 0 30px;
  background-color: #e9ebef;
  /* position: absolute;
  bottom: 0; */
  width: 100%;
}

.dashbordFooter span {
  padding: 32px 0 26px 0;
  color: #464646;
  font-size: 14px;
  line-height: 17px;
  font-family: "LatoSemibold";
}

/* privacy and terms*/

.careers-shorts {
  padding: 4rem 0 2rem 62px;
}

.privacy-head .statement-txt {
  font-family: "Lato-Black";
  color: #041564;
  font-size: 26px;
  line-height: 32px;
  margin-bottom: 15px;
}

.privacy-head .span-small {
  font-family: "Lato-Medium";
  color: #2c2c2c;
  font-size: 14px;
  line-height: 17px;
  margin-bottom: 30px;
}

.privacy-head p {
  font-family: "Lato-Regular";
  color: #2c2c2c;
  font-size: 14px;
  line-height: 24px;
  margin-bottom: 30px !important;
  width: 90%;
}

.privacy-head .collect-head {
  color: #041564;
  font-size: 20px;
  line-height: 24px;
  font-family: "Lato-Bold";
  margin-bottom: 20px;
}

.privacy-head .collect-head-small {
  color: #292929;
  font-size: 16px;
  line-height: 17px;
  font-family: "LatoSemibold";
  margin-bottom: 15px;
}

.privacy-head .collect-head-secondary {
  color: #292929;
  font-size: 16px;
  line-height: 17px;
  font-family: "Lato-Medium";
  margin-bottom: 15px;
}

.privacy-head .access-service {
  color: #041564;
  font-size: 18px;
  font-family: 17px;
  font-family: "Lato-Medium";
  margin-bottom: 20px;
}

.privacy-head ul {
  margin-bottom: 27px;
}

.privacy-head ul li {
  margin-bottom: 11px;
}

.hideNumber {
  visibility: hidden;
}

.margin4 {
  margin-top: 4rem;
}

.pageDown {
  margin-top: 6rem !important;
}

.howFaro .carousel-slider {
  height: 115px;
}

.formBackground .switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 30px;
}

.formBackground .switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.formBackground .slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  -webkit-transition: 0.4s cubic-bezier(0, 1, 0.5, 1);
  transition: 0.4s cubic-bezier(0, 1, 0.5, 1);
  border-radius: 4px;
}

.formBackground .slider:before {
  position: absolute;
  content: "";
  height: 21px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: #ffffff;
  -webkit-transition: 0.4s;
  -webkit-transition: 0.4s cubic-bezier(0, 1, 0.5, 1);
  transition: 0.4s cubic-bezier(0, 1, 0.5, 1);
  border-radius: 3px;
}

.formBackground input:checked+.slider {
  background-color: #5ca759;
}

.formBackground input:checked+.slider:before {
  -webkit-transform: translateX(26px);
  transform: translateX(26px);
}

.formBackground .slider.round {
  border-radius: 34px;
}

.formBackground .slider.round:before {
  border-radius: 50%;
}

.formBackground #round {
  border-radius: 34px;
}

.formBackground #round:before {
  border-radius: 50%;
}

@media (max-width: 667px) {
  .pageDown {
    margin-top: 10rem !important;
  }
}

.main-secPublic {
  padding: 0px;
  margin: 0px;
}

.react-tel-input .form-control {
  width: 500px !important;
}

p.text-warning {
  color: #dc3545 !important;
}

.spin-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.spinner-area {
  background: #ffffff;
  padding: 20px;
  box-shadow: 0px 0px 6px 2px rgba(0, 0, 0, 0.1);
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

// Antd
.ant-form-item-extra {
  font-size: 12px;
  font-weight: 500;
  margin-top: 4px;
}

.ant-upload-wrapper .ant-upload-drag .ant-upload {
  padding-top: 36px;
  padding-bottom: 36px;
}

.ant-form-item-has-error {
  .ant-radio-wrapper {
    border-color: var(--antd-error-color);
  }
}

.ant-tabs-nav {
  margin-bottom: 42px !important;

  div[role="tab"] {
    &>div {
      color: rgba(20, 20, 20, 0.54);
    }
  }

  .ant-tabs-tab-active {
    div[role="tab"] {
      &>div {
        color: var(--color-brand-primary);
      }
    }
  }
}

.m-icons {
  display: flex !important;
}

.upload-list {
  margin-bottom: 30px;

  li.ant-list-item {
    border-bottom: solid 1px var(--antd-input-border-color) !important;
    padding: 12px 4px;
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    &>div {
      display: flex;
      align-items: center;
    }

    .item-index {
      font-weight: 500;
      margin-right: 10px;
    }

    .item-name {
      color: var(--antd-primary-color);
    }

    .ant-list-item-action {
      em {
        display: none;
      }
    }
  }
}

.ant-modal-body video {
  object-fit: cover;
}

.font-16 {
  font-size: 16px !important;
}

.table-link {
  color: var(--color-brand-secondary);

  &:hover {
    color: rgba(0, 0, 0, 0.88);
  }
}

.table-action-icon {
  color: var(--color-brand-sub-heading-text);
  font-size: 20px !important;

  &:hover {
    color: var(--color-brand-primary);
  }
}

.languageOptions {
  margin-top: 20px;
  border-top: 1px solid var(--color-brand-default-border-color);

  .deleteIcon {
    color: var(--color-form-error);
  }

  .heading {
    margin-bottom: 10px !important;
  }

  .info {
    margin-bottom: 20px !important;
    color: var(--color-brand-sub-heading-text);
  }

  .add-locale-button {
    margin-top: 20px;
    margin-bottom: 20px;
    color: var(--color-brand-primary);
  }
}


.tab-label {
  display: flex;
  align-items: center;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: -0.02em;

  &>div {
    display: flex;
    margin-right: 10px;
  }
}

.tabs {
  :global(.action-buttons) {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 19px;
  }
}

.ant-pagination {
  margin-top: 30px !important;
}

.table-data-image {
  border-radius: 5px;
  object-fit: cover;
  cursor: pointer;
}