@import "../../../../styles/mixins";

.button {
  @include button(7px 45px, 15px, "none", 100px);
  box-shadow: none;

  &:global(.ant-btn-default) {
    &:not(.ant-btn-disabled) {
      &:hover {
        @include horizontal-gradient(var(--color-brand-secondary), var(--color-brand-primary));
      }
    }
  }

  &:disabled {
    opacity: 0.8;
  }
}