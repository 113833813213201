.heading {
    margin: 0px 0px 20px 0px !important;
}

.notification {
    background-color: var(--color-form-wrapper-header);
    padding: 15px 20px;
    margin-bottom: 15px;
    border-radius: 5px;
    color: var(--color-brand-sub-heading-text);

    .title-container {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .title {
            font-size: 14px;
            color: var(--color-push-notification-heading);
            font-weight: 500;
        }

        div {
            display: flex;

            span {
                font-size: 20px;
                cursor: pointer;
            }

            .edit {
                color: var(--color-push-notification-highlight);
                margin-right: 5px;
            }

            .delete {
                color: var(--color-form-error);

            }
        }
    }

    .schedule {
        font-weight: 500;
    }

    .date {
        color: var(--color-push-notification-highlight);
        font-weight: 500;
        margin-left: 5px;
    }
}

.schedule-date-picker {
    :global {
        .ant-picker-footer {
            position: relative;

            .ant-picker-footer-extra {
                padding: 5px;
                width: calc(100% - 50px);

                :local(.button-cont) {
                    display: flex;
                    gap: 10px;

                    button {
                        padding: 2px 7px;
                    }
                }
            }

            .ant-picker-ranges {
                position: absolute;
                top: 0;
                right: 0;
                padding-right: 5px !important;
            }
        }
    }
}