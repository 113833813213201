.footer-new {
  background: #0d1117;
  width: 100%;
  font-family: "Inter", "sans-serif";
  padding: 25px 0px !important;
}

.footer-new .container {
  padding: 0px 30px;
}

.footer-new .b-bottom {
  border-bottom: 1px solid #21262d;
}
.footer-new ul li {
  margin-right: 0px;
}
.footer-new ul li + li {
  margin-left: 25px !important;
}

.social-media-links i {
  font-size: 22px;
  height: 35px;
  width: 35px;
  color: white;
  border-radius: 50%;
  padding: 6px 8.5px;
  border: 0.7px solid transparent;
}
.links {
  margin-right: auto;
  color: #646f7c;
}
.links a:first-of-type {
  /* margin-right: 30px; */
}
.social-media-links i:hover {
  animation: bg-color linear 0.2s forwards;
}

@keyframes bg-color {
  0% {
    background-color: none;
    border: 0.7px solid #982ff7;
  }
  15% {
    background-color: none;
  }
  100% {
    background-color: #982ff7;
    border: 0.7px solid #982ff7;
  }
}

.footer-new .container > div p {
  font-size: 14px !important;
  color: #646f7c !important;
  text-align: center;
}

.footer-new .links a {
  font-family: "Inter", "sans-serif";
  font-size: 14px;
  color: #646f7c;
  transition: color ease-in-out 0.3s;
}

.footer-new .links a:hover {
  color: white;
}

@media (max-width: 992px) {
  .footer-new .container div {
    flex-direction: column;
  }
  .footer-new .container > div p {
    line-height: 22px;
  }
  .social-media-links {
    margin-top: 15px !important;
  }
  .links {
    margin: 10px 0px;
  }
}
