.tag {
  margin-bottom: 15px;
}

.cover-wrapper {
  position: relative;
  .overlay {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(0, 0, 0, 0.4);
    border-radius: 8px 8px 0 0;
  }
}
