@import "../../../../../styles/mixins";

.button {
  &:not(.link) {
    @include button-plain(8px 17px, 14px, none, 5px);
    display: flex;
    align-items: center;
  }

  &.link {
    padding: 0;
    color: var(--color-brand-text);
    display: flex;
    align-items: center;

    &:not(.disabled) {
      &:hover {
        color: var(--color-brand-primary);
      }
    }

    &:disabled {
      background: transparent;
      border: transparent !important;
      color: #BFBFBF !important;
      box-shadow: none !important;
      cursor: not-allowed;

      &:hover {
        background: transparent;
      }
    }
  }

  &.cancel {
    background: #F0F0F0;
    border: solid 1px darken(#F0F0F0, 8%);
    color: var(--color-brand-text) !important;
    box-shadow: none !important;

    &:hover {
      background: darken(#F0F0F0, 8%) !important;
      border: solid 1px darken(#F0F0F0, 11%) !important;
    }
  }

  &:disabled {
    background: #F5F5F5;
    border: solid 1px darken(#F5F5F5, 8%) !important;
    color: #BFBFBF !important;
    box-shadow: none !important;
    cursor: not-allowed;

    &:hover {
      background: #F5F5F5 !important;
    }
  }

  &.fullwidth {
    width: 100%;
    justify-content: center;
    gap: 5px;
  }

  &.default {
    background-color: var(--antd-default-color);
    border: solid 1px var(--color-brand-default-border-color);
    color: black !important;

    &:hover {
      background: darken(#F0F0F0, 8%);
      border: solid 1px darken(#f0f0f0, 20%);
      box-shadow: 0px 0px 0px 4px rgb(240 240 240 / 9%);
    }

    &:disabled {
      background: #F5F5F5;
      border: solid 1px darken(#F5F5F5, 8%) !important;
      color: #BFBFBF !important;
      box-shadow: none !important;
      cursor: not-allowed;

      :global(.ant-tag) {
        color: #BFBFBF !important;
        background: #ebebeb !important;
      }

      &:hover {
        background: #F5F5F5 !important;
      }
    }
  }

  :global {
    .ant-btn-loading-icon {
      display: flex;
    }
  }

  &:global(.view-merchant) {
    margin-right: 20px;

    svg {
      margin-right: 10px;
    }
  }
}