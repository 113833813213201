.profile-details-container {
  width: 90%;
  display: flex;
}

.profile-details-container label {
  color: #595959 !important;
}

.text-normal {
  color: #141414 !important;
}

.editable-profile-image {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.profile-image-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.avatar {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  object-fit: contain;
  border: 1px solid #D9D9D9;
}

.default-background {
  background-color: #D9D9D9;
  border: 1px solid #D9D9D9;
}

.edit {
  color: #9B43FD;
  font-size: 12px;
}

.faro-purple {
  color: #9B43FD;
}