@mixin box-shadow($top, $left, $blur, $color, $inset: false) {
  @if $inset {
    -webkit-box-shadow: inset $top $left $blur $color;
    -moz-box-shadow: inset $top $left $blur $color;
    box-shadow: inset $top $left $blur $color;
  }

  @else {
    -webkit-box-shadow: $top $left $blur $color;
    -moz-box-shadow: $top $left $blur $color;
    box-shadow: $top $left $blur $color;
  }
}

@mixin text-shadow($top, $left, $blur, $color, $inset: false) {
  @if $inset {
    -webkit-text-shadow: inset $top $left $blur $color;
    -moz-text-shadow: inset $top $left $blur $color;
    text-shadow: inset $top $left $blur $color;
  }

  @else {
    -webkit-text-shadow: $top $left $blur $color;
    -moz-text-shadow: $top $left $blur $color;
    text-shadow: $top $left $blur $color;
  }
}

@mixin linearGradient($top, $bottom) {
  background: $top;
  /* Old browsers */
  background: -moz-linear-gradient(top, $top 0%, $bottom 100%);
  /* FF3.6+ */
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, $top), color-stop(100%, $bottom));
  /* Chrome,Safari4+ */
  background: -webkit-linear-gradient(top, $top 0%, $bottom 100%);
  /* Chrome10+,Safari5.1+ */
  background: -o-linear-gradient(top, $top 0%, $bottom 100%);
  /* Opera 11.10+ */
  background: -ms-linear-gradient(top, $top 0%, $bottom 100%);
  /* IE10+ */
  background: linear-gradient(to bottom, $top 0%, $bottom 100%);
  /* W3C */
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffffff', endColorstr='#000000', GradientType=0);
  /* IE6-9 */
}

@mixin horizontal-gradient($from, $to, $stop: 100%) {
  background-color: $from;
  background-image: -moz-linear-gradient(left, $from 0%, $to $stop);
  background-image: -webkit-gradient(linear, left top, right top, color-stop(0%, $from), color-stop($stop, $to));
  background-image: -webkit-linear-gradient(left, $from 0%, $to $stop);
  background-image: -o-linear-gradient(left, $from 0%, $to $stop);
  background: -ms-linear-gradient(left, $from 0%, $to $stop);
  background: linear-gradient(to right, $from 0%, $to $stop);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='$from', endColorstr='$to', GradientType=1);
}

@mixin uldefault {
  margin: 0px;
  padding: 0px;
  list-style: none;
}

@mixin transform($transforms) {
  -moz-transform: $transforms;
  -o-transform: $transforms;
  -ms-transform: $transforms;
  -webkit-transform: $transforms;
  transform: $transforms;
}

@mixin transition($transition) {
  -moz-transition: $transition;
  -o-transition: $transition;
  -webkit-transition: $transition;
  transition: $transition;
}

@mixin border-radius($radius) {
  -webkit-border-radius: $radius;
  border-radius: $radius;
  background-clip: padding-box;
  /* stops bg color from leaking outside the border: */
}

@mixin button($padding, $font-size, $text-transform, $radius) {
  @include border-radius($radius);
  display: inline-block;
  @include horizontal-gradient(var(--color-brand-secondary), var(--color-brand-secondary));
  background: -ms-linear-gradient(to right, var(--color-brand-secondary) 0%, var(--color-brand-primary) 100%) !important;
  background: linear-gradient(to right, var(--color-brand-secondary) 0%, var(--color-brand-primary) 100%) !important;
  font-family: var(--font-family-secondary);
  font-size: $font-size;
  font-weight: 500;
  color: var(--color-brand-button-text-color) !important;
  padding: $padding;
  cursor: pointer;
  text-decoration: none;
  text-transform: $text-transform;
  outline: none;
  letter-spacing: -0.01em;
  border: none;
  height: auto;
  @include transition(all 0.25s ease-in-out);

  &:hover {
    // background: var(--color-brand-primary) !important;
    box-shadow: 0px 0px 0px 4px rgb(199 0 235 / 9%);
  }
}

@mixin button-plain($padding, $font-size, $text-transform, $radius) {
  @include border-radius($radius);
  display: inline-block;
  background: var(--color-brand-button-color);
  font-family: var(--font-family-secondary);
  font-size: $font-size;
  font-weight: 500;
  color: var(--color-brand-button-text-color) !important;
  padding: $padding;
  cursor: pointer;
  text-decoration: none;
  text-transform: $text-transform;
  outline: none;
  letter-spacing: -0.01em;
  border: none;
  height: auto;
  @include transition(all 0.25s ease-in-out);

  &:hover {
    background: darken(#9B43FD, 4%) !important;
    box-shadow: 0px 0px 0px 4px rgb(199 0 235 / 9%);
  }
}

@mixin line-clamp($lines) {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: $lines; // Limits the number of lines shown
}