//Scheduled and sent notifications list
.notification-section-wrapper {
  padding: 0px 20px 10px 20px;
  box-shadow: 0px 0px 40px rgba(9, 9, 9, 0.05);
  border-radius: 10px;
  h6 {
    padding: 25px 20px;
    margin-bottom: 0;
    display: flex;
    font-size: 14px;
    color: var(--color-push-notification-heading);
    div {
      margin-right: 10px;
      span {
        font-size: 16px;
        height: 100%;
        vertical-align: middle;
      }
    }
  }
}

.push-notification-form {
  :global {
    .ant-radio {
      .ant-radio-inner {
        display: none;
      }
    }
  }
}

//Sent Notifications list
.sent-notifications {
  background-color: var(--color-form-wrapper-header);
  padding: 15px 20px;
  margin-bottom: 15px;
  border-radius: 5px;
  h6 {
    padding: 0;
    div {
      margin-left: auto;
      margin-right: 0;
      cursor: pointer;
      span {
        font-size: 20px;
        color: var(--color-form-error);
      }
    }
  }
  p {
    padding: 0;
    color: var(--color-brand-sub-heading-text);
    &.blue {
      color: var(--color-push-notification-highlight);
      font-weight: 500;
    }
  }
}

//Iphone Mock
.iphone-mock-wrapper {
  padding-left: 12.5px;
  padding-right: 5px;
  position: relative;
  margin-bottom: 20px;
  :global(.ant-image) {
    height: 400px;
    width: 100%;
    overflow: hidden;
  }
  .notification {
    position: absolute;
    bottom: 80px;
    left: 50px;
    display: flex;
    height: 81px;
    align-items: center;
    background: rgba(0, 0, 0, 0.3);
    --webkit-backdrop-filter: blur(40px);
    width: calc(100% - 90px);
    padding: 10px;
    border-radius: 15px;
    :global(.ant-image) {
      margin-right: 10px;
      img {
        border-radius: 10px;
      }
    }
    :global(.text) {
      width: calc(100% - 60px);
      align-self: flex-start;
      color: var(--color-brand-button-text-color);
      p {
        line-height: 1.4;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        --webkit-line-clamp: 1;
      }
    }
  }
}

.form-wrapper-text {
  color: var(--color-push-notification-heading);
  font-weight: 500;
}

//Statistics tab
.statistics-wrapper {
  :global(.ant-statistic) {
    box-shadow: 0px 16px 40px rgba(9, 9, 9, 0.05);
    padding: 20px;
    border-radius: 10px;
  }
}

@media (max-width: 992px) {
  .iphone-mock-wrapper {
    width: 425px;
  }
}
