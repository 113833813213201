 //Pass Form and preview styling

.addpassform {
  background-color: #ffffff;
  padding: 1rem;
  display: flex;
  flex-flow: column;
  max-height: 700px;
  overflow: scroll;
  }
    .passLogoImage{
    max-height: 30px;
    }
    .passStripImage{
    width: 100%;
    }
    
    .pass-card-ios{
    padding:4px;
    width: 77%;
    margin-top: 1.5rem;
    background-color: #ffffff;
    border-radius: 15px;
    box-shadow:
    0 1px 1px hsl(0deg 0% 0% / 0.075),
    0 2px 2px hsl(0deg 0% 0% / 0.075),
    0 4px 4px hsl(0deg 0% 0% / 0.075),
    0 8px 8px hsl(0deg 0% 0% / 0.075),
    0 16px 16px hsl(0deg 0% 0% / 0.075);
    }

    .pass-card-android{
        padding:4px;
        width: 78%;
        margin-top: 1.5rem;
        background-color: #ffffff;
        border-radius: 15px;
        box-shadow:
        0 1px 1px hsl(0deg 0% 0% / 0.075),
        0 2px 2px hsl(0deg 0% 0% / 0.075),
        0 4px 4px hsl(0deg 0% 0% / 0.075),
        0 8px 8px hsl(0deg 0% 0% / 0.075),
        0 16px 16px hsl(0deg 0% 0% / 0.075)
      ;
        }

    .passcard-header .tierDetails{
      display:flex;
      flex-direction: column;
    }

    .tier-info {
      display: flex;
      flex-direction: column;
    }
    .tier-heading{
      margin-top: 3px;
      font-size: 13px;
    }
     .tier-description{
        font-size: 13px;
        font-weight: 400;
    }
    .info-container{
      display: flex; 
      justify-content: space-between; 
      align-items: center;
      width: 100%;
    }
    .points-info-android {
      display:flex;
      justify-content: center;
      align-items: center;
      padding-right: 15px;
    }
    .points-info-ios {
        width: 100%;
        display:flex;
        flex-direction: column;
        margin-right:1rem;
        align-items: flex-end;
    }
    .points-amount {
      font-size: 13px;
      font-weight: 400;
      margin-right:5px;
    }

    .passcard-header{
        display:flex;
        justify-content: space-between;
        padding: .4rem;
      }

    .passcard-qr{
      height: 6rem;
      margin: 1.5rem 0 .2rem 0
    }

    .passcard-body{
        display: flex;
        flex-flow: column;
       align-items: center;
    }
  
    .passcard-strip{
      height: 4.5rem;
      border-radius: 0 0 15px 15px;
      width: 100%;
      margin-bottom: -4px;
    }
    .passcard-strip-ios{
        height: 4.5rem;
        width: 100%;
      }

    .tier-benefits{
        width: 100%;
        display:flex;
        flex-direction: column;
        margin-left:1rem;
    }
    .passcard-logo{
        height: 2rem;
      }

    .preview-container{
     width:400px;
     padding:30px 50px 30px 50px;
     background:#f5f5f5; ;
     display:flex;
     flex-flow: column;
     overflow: scroll;
     min-height: 700px;
    } 


    .phone-mode-container{
        width: 100%;
         display: flex;
        justify-content: center;
    }
    .pass-apple-icon{
        font-size: 22px;
         margin-right: 5px;
          color: black;
    }
    .pass-android-icon{
        font-size: 22px;
        margin-right: 5px;
         color: #a4c639;
    }
    .phone-mockup{
        margin-top: .5rem;
        width:100%;
        max-height: 570px;
    }

    .pass-card-container{
        display: flex;
        justify-content: center;
        padding:2px;
        position: absolute;
        width: 100%;
  
      }

      .pass-form-container{
       display: flex;
      }

      //Tier Cards list container styling
      .tierCardContainer{
        display: flex;
        background-color: #f5f5f5;
        align-items: center;
        flex-flow: column;
        overflow: scroll;
        height: 550px;
        padding-bottom: 2rem;
        }  
        
        .tierCard{
        width:60%;
        display: flex;
        align-items: center;
        justify-content: center;
        }
        
        .addTierCard{
        background-color:#ffffff ;
        padding: 1rem;
        display: flex;
        flex-flow: column;
       
        }
        
        .tierCardsHeading{
          position: absolute;
          left:10px;
          top:1.5rem;
          font-size: 1.2rem; 
          margin-left: 10%;
         }
        
           
       
         .tierCardsList{
           margin-top: 4rem;
         }
         
         .passHeading{
         display:flex;
         }
         
       .tierCardActive{
         border: 2px solid #902ffd;
         border-color: #902ffd;
       
         }
         .tierCardError{
           border: 1px solid #b80336 !important;
           border-color: #b80336 !important;
         
         }
          .tierSingleCard{
          width:15rem;
          height:6rem;
          margin:.4rem
          }
          .tierCardIcon{
           height: 1.7rem;
           width:1.7rem;
           border-radius: 50%;
           padding:5px;
           background-color: #57cc99;
           color:#ffffff;
           display:block;
           margin-top:0.2rem;
           font-size: 1.1rem;
         
          }
          .tierCard{
            width:15rem !important;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-top: .3rem;
            background-color: #f5f5f5;
            border: 2px #57cc99 dashed;
            height: 6rem;
          }
          .tierFormContainer {
            display: flex;
            background-color: #f5f5f5;
            align-items: center;
            flex-flow: column;
            overflow: scroll;
            height: 750px;
            }  
    .tierCardActive {
    border: 1px solid #902ffd;
    border-color: #902ffd;
    }
            
     .add-tier-card-row{
      display: flex;
      align-items: center;
      justify-content: center;
      flex-flow: column;
    }
    .tier-single-card-body{
    display: flex; 
    align-items: center; 
    justify-content: center;
    flex-flow: column
    }
    .pass-logo-upload-box{
    display: flex;
    width: max-content;
   gap:32px;
    flex-direction: row;
    }