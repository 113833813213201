@mixin epub-reader-buttons{
  top:auto !important;
  height: 50px;
  width: 50px;
  bottom: 30px !important;
}

.epub-reader-modal {
  :global(.ant-modal-body) {
    div {
      div {
        button:nth-of-type(2) {
          left: calc(50% - 50px) !important;
          @include epub-reader-buttons;
        }
        button:nth-of-type(3) {
          @include epub-reader-buttons;
          right: calc(50% - 50px) !important;
        }
      }
    }
  }
}
