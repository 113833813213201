.breadcrumb {
  margin-bottom: 5px;
  .breadcrumb-item {
    font-family: var(--font-family-roboto);
    font-size: var(--font-size-breadcrumb);
    color: var(--color-brand-text);
    font-weight: 400;
    a {
      color: var(--color-brand-primary);
      &:hover {
        color: var(--color-brand-text);
        background: none;
      }
    }
  }
}
