@import "../../../../styles/mixins";

.before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  @include horizontal-gradient(var(--color-brand-secondary),
    var(--color-brand-primary));
  opacity: 0;
  @include transition(all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1));
  z-index: -1;
}

.navigation {
  background: var(--color-brand-navigation) !important;
  overflow: auto;
  height: 100vh;
  left: 0;
  top: 0;
  bottom: 0;

  .nav-wrapper {
    padding-left: 20px;
    @include transition(all 0.3s ease);

    &.collapsed {
      padding-left: 10px;
    }
  }

  .logo {
    padding: 41px 0px 0px 0px;
    @include transition(all 0.3s ease);

    &.collapsed {
      width: 24px;
      height: 24px;
      padding: 41px 0px 0px 4px;
      margin-left: 8px;
      overflow: hidden;
    }
  }

  .logoimage {
    height: 60px;
    width: 60px;

    &.collapsed {
      width: 20px;
      height: 20px;
      padding: 41px 0px 0px 4px;
      margin-left: 8px;
      overflow: hidden;
    }
  }

  .language-options {
    :global {
      .ant-dropdown-trigger {
        background-color: none;
        margin: 10px 10px 20px 10px;
        color: var(--color-brand-text);
        display: flex;
        width: max-content;

        img {
          height: 30px;
        }
      }

      .anticon-caret-down {
        padding-bottom: 5px;

        svg {
          path {
            color: white;
          }
        }
      }
    }
  }


  .menu {
    width: 100%;
    background: none;
    font-family: var(--font-family-secondary);

    li {
      width: 100%;
      margin: 0;
      font-size: 15px;
      font-weight: 600;
      border-radius: 0;
      position: relative;
      background: none !important;
      color: var(--color-brand-navigation-text-color);
      line-height: 46px;
      height: 50px;
      line-height: 57px;
      margin-bottom: 1px;

      div {
        :global(.m-icons) {
          height: 100%;
          vertical-align: middle;
          font-size: 24px !important;
        }

        margin-right: 10px;
      }

      &:before {
        @extend .before;
      }

      svg {
        path {
          @include transition(all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1));
        }
      }

      &:hover {
        color: #fff;

        &::before {
          opacity: 1;
        }

        svg {
          path {
            fill: #fff;
          }
        }
      }

      div[role="menuitem"] {
        margin: 0;
        width: 100%;
        height: 50px;
        line-height: 57px;
        color: var(--color-brand-navigation-text-color);
        border-radius: 0;

        &:hover {
          background: none;

          &:before {
            opacity: 1;
          }
        }

        &::before {
          @extend .before;
        }
      }

      ul {
        background: var(--color-brand-navigation-sub-menu) !important;

        li {
          padding-left: 45px !important;
          display: flex;

          &:hover {
            background: linear-gradient(to right,
                var(--color-brand-secondary) 0%,
                var(--color-brand-primary) 100%) !important;

            &:before {
              opacity: 1;
            }
          }

          &::before {
            @extend .before;
          }
        }
      }
    }

    @media (max-width: 768px) {
      :global(.ant-menu-item) {
        padding-left: 15px;
      }
    }

    :global(.ant-menu-submenu .ant-menu-sub .ant-menu-item-active) {
      background: linear-gradient(to right,
          var(--color-brand-secondary) 0%,
          var(--color-brand-primary) 100%) !important;
    }
  }

  .collapse-btn {
    width: 28px;
    height: 28px;
    background: rgba(57, 60, 65, 0.61);
    border: none;
    @include border-radius(3px);
    box-shadow: none;
    outline: none;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    @include transition(all 0.3s ease);
    margin-left: 20px;
    margin-top: 15px;
    margin-bottom: 70px;

    &::after {
      display: none;
    }

    &.collapsed {
      margin-left: 14px;
      margin-top: 15px;
      margin-bottom: 120px;
    }

    &:hover{
      background-color: rgba(95 100 109 / 61%)!important;
      box-shadow: 0 0px 8px rgba(95, 100, 109, 0.40);
    }
  }

  .button-cont {
    display: flex;
    flex-wrap: wrap;
    position: fixed;
    bottom: 0;
    left: 0;
    background: var(--color-brand-navigation) !important;

    &.collapsed {
      width: 59px !important;

      button {
        div {
          margin-right: 0;
        }
      }
    }

    button {
      &:global(.ant-btn) {
        color: var(--color-brand-navigation-text-color);
        border-radius: 0;
        border: none;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        @include transition(all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1));

        div {
          margin-right: 10px;
          height: 24px;
        }

        &:hover {
          @include transition(all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1));
          @include horizontal-gradient(var(--color-brand-secondary),
            var(--color-brand-primary));
          border: none;
          color: #fff;

          &::before {
            opacity: 1;
          }

          svg {
            path {
              fill: #fff;
            }
          }
        }

        &:active {
          @include horizontal-gradient(var(--color-brand-secondary),
            var(--color-brand-primary));
          border: none;
        }

        &.collapsed {
          width: 100%;

          :global(div + span) {
            display: none;
          }
        }
      }
    }
  }

  :global(.ant-menu-inline .ant-menu-submenu) {
    padding-left: 0 !important;
    height: auto !important;
  }

  :global(.ant-menu-inline .ant-menu-submenu::before) {
    display: none;
  }

  :global(.ant-menu-inline .ant-menu-submenu .ant-menu-submenu-title::before) {
    height: 50px !important;
  }

  :global(.ant-menu.ant-menu-root .ant-menu-item-divider) {
    height: 1px !important;
    background: var(--color-brand-navigation-divider) !important;
    margin: 12px 0px;
  }

  :global(.ant-menu-item.ant-menu-item-active) {
    color: #fff !important;
  }

  :global(.ant-menu-item.ant-menu-item-active svg path) {
    fill: #fff !important;
  }

  :global(.ant-menu-item.ant-menu-item-active:before) {
    opacity: 1 !important;
  }

  :global(.ant-menu-submenu.ant-menu-submenu-active > div) {
    color: #fff !important;
  }

  :global(.ant-menu-submenu.ant-menu-submenu-active > div svg path) {
    color: #fff !important;
    fill: #fff !important;
  }

  :global(.ant-menu-submenu.ant-menu-submenu-active > div:before) {
    opacity: 1 !important;
  }

  :global(.ant-menu.ant-menu-root .ant-menu-item-divider:before) {
    display: none;
  }
}

.content-wrapper {
  background: var(--color-brand-bg-content-area);
  padding: var(--content-area-padding);

  :global {
    .pt-0 {
      padding-top: 0;
    }
  }
}

:global {
  .ant-menu-submenu.ant-menu-submenu-popup li {
    display: flex !important;

    &:hover {
      background: linear-gradient(to right,
          var(--color-brand-secondary) 0%,
          var(--color-brand-primary) 100%) !important;
    }

    div {
      .m-icons {
        margin-right: 10px;
        height: 100%;
        vertical-align: middle;
      }
    }
  }
}

.locale-dropdown {

  :global {
    .ant-dropdown-menu-item {
      padding: 10px 25px;

      .ant-dropdown-menu-item-icon {
        height: 30px !important;
      }
    }
  }

  // background: red;
  // ul {
  //   li {
  //     padding: 10px 25px;
  //     background: red !important;
  //     img {
  //       width: 20px !important;
  //     }
  //   }
  // }
  // :global {
  //     .ant-dropdown-menu-item {
  //       background: red !important;
  //     }
  // }
  // .ant-dropdown-menu-item
  //   padding: 10px 25px;
  //   :global(.ant-dropdown-menu-item-icon) {
  //     height: 30px !important;
  //   }
  // }

}

:global {
  .ant-menu-submenu.ant-menu-submenu-popup li.ant-menu-item-selected {
    background: linear-gradient(to right,
        var(--color-brand-secondary) 0%,
        var(--color-brand-primary) 100%) !important;
  }
}