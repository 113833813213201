.error-container {
  position: relative;
}
.error-container.isdrop {
  margin-top: 60px;
}
.close-icon {
  position: absolute !important;
  right: 0.6rem !important;
  top: 0.6rem !important;
  background: transparent !important;
  border: none !important;
}
