
/* ------------ Tabs styling start here ---------- */
.tabs-wrapper button {
  padding-right: 0 !important;
  padding-left: 0 !important;
  font-family: "Inter", sans-serif !important;
  font-size: 20px;
  font-weight: 600;
  line-height: 21px;
  letter-spacing: -0.01em;
  color: #16161A !important;
  outline: none !important;
  border: none !important;
}
.tabs-wrapper button:hover {
  border-color: white !important;
}
.tabs-wrapper li.nav-item {
  margin-right: 35px !important;
}
.tabs-wrapper li.nav-item button.active {
  color: #9600FF !important;
  border-bottom-color: #9600FF !important;
}
/* ------------ Tabs styling end here ---------- */