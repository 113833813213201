.filters-wrapper {
  display: flex;
  justify-content: space-between;
  margin-bottom: 40px;
  .filters {
    display: flex;
    gap: 10px;
  }
  .extra {
    display: flex;
    gap: 25px;
  }
}