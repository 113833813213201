.input-item-wrapper {
  &:global(.ant-form-item-with-help.ant-form-item-has-success .ant-form-item-explain-error) {
    color: #10c510;
  }
  &:global(.ant-form-item-with-help.ant-form-item-has-success .ant-input-status-success) {
    border-color: #10c510;
  }
  :global {
    .ant-form-item-explain-error {
      text-align: left;
    }
  }
}

.input {
  border: 1px solid #d9d9d9;
  padding-top: 9px;
  padding-bottom: 9px;
  font-family: var(--font-family-secondary);
  &:hover {
    border-color: var(--color-brand-primary);
  }
  &:focus {
    border-color: var(--color-brand-primary);
    box-shadow: 0px 0px 0px 2px rgba(199, 0, 235, 0.2);
  }
}
