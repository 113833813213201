.upload-file-wrapper {
  margin-bottom: 15px;
}
.upload-file-wrapper input[type="file"] {
  display: none;
}
.upload-file-wrapper input[type="file"] + label {
  border: 3px dashed rgb(204, 204, 204);
  position: relative;
}
.upload-file-wrapper input[type="file"] + label .media-file {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 20px;
}
.upload-file-wrapper input[type="file"] + label .media-file img {
  width: 35px;
  height: auto;
  margin-bottom: 4px;
}
.upload-file-wrapper input[type="file"] + label img {
  max-width: 100%;
  height: 175px;
  object-fit: cover;
}
.upload-file-wrapper input[type="file"] + label::after {
  display: none;
}

.upload-file-wrapper input[type="file"] + label.initial-label {
  border: none;
}
.upload-file-wrapper input[type="file"] + label.initial-label::after {
  content: "";
  width: 231px;
  height: 175px;
  display: inline-block;
  background: url("../../../../assets/images/no-image.png") no-repeat center
    center / contain;
  border: 3px dashed rgb(204, 204, 204);
}

.upload-file-wrapper input[type="file"] + label.loading:before {
  content: "Uploading...";
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  position: absolute;
  left: 0;
  top: 0;
  font-size: 1rem;
  font-weight: 700;
  color: #fff;
}
.upload-file-wrapper input[type="file"] + label.error::after {
  border-color: red;
}
.upload-file-wrapper .fileText {
  margin-bottom: 6px;
}

.upload-file-wrapper .view-file {
  background: none;
  margin-left: 16px;
  border: solid 1px #676767;
  padding: 5px 10px;
  display: inline-flex;
  flex-direction: row;
  color: #000;
  align-items: center;
  border-radius: 9px;
}
.upload-file-wrapper .view-file img {
  width: 20px;
  margin-right: 5px;
}
