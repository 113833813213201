@import "../../../../styles/mixins";

.card {
  @include border-radius(10px);
  @include box-shadow(0px, 16px, 40px, rgba(9, 9, 9, 0.05));
  border: none;
  height: 100%;
  display: flex;
  flex-direction: column;
  font-family: var(--font-family-secondary);
  &.autoHeight {
    height: auto;
  }
  .head {
    background: red;
  }
  :global {
    .ant-card-head-title {
      font-weight: 500;
    }
    .ant-card-body {
      flex: 1;
    }
    .ant-card-meta-detail div:first-child {
      margin-bottom: 3px;
    }
    .ant-card-meta-title {
      font-family: var(--font-family-secondary);
      font-size: 15px;
      font-weight: 400;
      letter-spacing: -0.02em;
    }
    .ant-card-meta-description {
      font-family: var(--font-family-secondary);
      font-size: 13px;
      font-weight: 500;
      letter-spacing: -0.02em;
      color: var(--color-brand-sub-heading-text);
    }
    .ant-card-actions {
      li {
        span {
          display: flex;
          align-items: center;
          justify-content: center;
          height: 100%;
        }
        button {
          width: 100%;
          justify-content: center;
          align-items: center;
          height: auto;
          color: rgba(0, 0, 0, 0.45);
        }
      }
    }
    .ant-card-cover {
      img {
        object-fit: cover;
      }
    }
  }
  &.has-header {
    :global {
      .ant-card-cover img {
        border-radius: 0;
      }
    }
  }
  &.small {
    :global {
      .ant-card-head {
        padding-left: 16px;
        padding-right: 16px;
      }
      .ant-card-head-title {
        padding-top: 8px;
        padding-bottom: 8px;
      }
      .ant-card-body {
        padding: 16px;
      }
      .ant-card-actions {
        li {
          margin: 8px 0;
        }
      }
    }
  }
  &.selectedCard {
    background: #FCF8FF;
    border: 1.5px  #9B43FD solid;        
  }
}

.show-active-date {
  :global {
    .ant-card-body {
      position: relative;
      padding-top: 40px;
        .active-date{
        font-size: 14px;
        background-color: rgb(248, 246, 93);
        position: absolute;
        color: #8C8C8C;
        padding: 5px 24px;
        width: 100%;
        top: 0;
        left: 0;

        strong {
          color: black;
          margin-right: 10px;
        }
        }
    }
  }
}